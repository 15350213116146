import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CardLayoutInner,
    StyledWrapperStandard,
    TitleWrapper,
} from '../../components/common.style';
import { H4Title } from '../../components/common.styles.font';
import CardProduct from '../../components/CardProduct';
import PDFSplitterImg from '../../assets/products/pdfSplitter/imgs/PDF_Splitter.png';
import BlubinaCoreImg from '../../assets/products/blubinaCore/imgs/Blubina-Core.png';
import uPointImg from '../../assets/products/uPointDMS/imgs/uPoint.png';
import { productTags } from '../../data/mainDetails';

interface Props {
    curLang: string;
}

const ProductSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard id="main__products" className="bg-tertiary">
            <TitleWrapper className="title-card-wrapper">
                <H4Title
                    className="title-span-tertiary"
                    data-aos-delay="80"
                    data-aos="fade-up"
                >
                    <span>{t('products')}</span>
                </H4Title>
            </TitleWrapper>

            <CardLayoutInner className="col-3">
                <CardProduct
                    curLang={curLang}
                    img={PDFSplitterImg}
                    tagArr={productTags.pdfSplitter}
                    title={t('product.pdfSplitter.main.title')}
                    subtitle={t('product.pdfSplitter.main.subtitle')}
                    description={t('product.pdfSplitter.main.desc')}
                    clickable={true}
                    link={`/${curLang}/product/pdf-splitter`}
                />
                <CardProduct
                    curLang={curLang}
                    img={BlubinaCoreImg}
                    tagArr={productTags.blubinaCore}
                    title={t('product.blubinaCore.main.title')}
                    subtitle={t('product.blubinaCore.main.subtitle')}
                    description={t('product.blubinaCore.main.desc')}
                    clickable={true}
                    link={`/${curLang}/product/blubina-core`}
                />
                <CardProduct
                    curLang={curLang}
                    img={uPointImg}
                    tagArr={productTags.uPointDMS}
                    title={t('product.uPointDMS.main.title')}
                    subtitle={t('product.uPointDMS.main.subtitle')}
                    description={t('product.uPointDMS.main.desc')}
                    clickable={true}
                    link={`https://upointdms.com/${curLang}`}
                />
            </CardLayoutInner>
        </StyledWrapperStandard>
    );
};

export default ProductSection;
