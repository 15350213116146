import React from 'react';
import { useTranslation } from 'react-i18next';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import {
    Wrapper,
    Inner,
    CopyrightTxt,
    LinksWrapper,
    Link,
    Line,
} from './style';

interface Props {
    curLang: string;
    curView?: string | null;
    isHomePage?: boolean;
}

const Footer: React.FC<Props> = ({ curView, curLang }) => {
    const { t } = useTranslation();
    const curDate = new Date();
    const curYear = curDate.getFullYear();
    return (
        <Wrapper className={curView === 'product' ? 'product' : 'homepage'}>
            <Inner>
                <LinksWrapper>
                    <Link
                        className={`lang-${curLang}`}
                        href={`/${curLang}`}
                        target="_blank"
                        rel="noopner noreferrer"
                    >
                        Blubina
                        <UilArrowUpRight className="icon icon-link" />
                    </Link>
                    <Line>|</Line>

                    <Link
                        className={`lang-${curLang}`}
                        href={`/${curLang}/privacy-policy `}
                        target="_blank"
                        rel="noopner noreferrer"
                    >
                        Blubina {t('privacyPolicy.main.title')}
                        <UilArrowUpRight className="icon icon-link" />
                    </Link>
                </LinksWrapper>

                <CopyrightTxt className={`lang-${curLang}`}>
                    © {curYear}, Blubina GmbH Munich. Names and logos of
                    Outlook, SharePoint, Windows and others are registered
                    trademarks of Microsoft.
                </CopyrightTxt>
            </Inner>
        </Wrapper>
    );
};

export default Footer;
