import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Inner, ActionBtnsWrapper } from './style';
import { TitleWrapper } from '../../components/common.style';
import { H4Title } from '../../components/common.styles.font';
import VideoSlides from '../../components/VideoSlides';
import FeatureActionBtn from '../../components/FeatureActionBtn';

interface Action {
    id: string;
    idx: number;
    txt: string;
    icon: any;
}

interface Video {
    id: string;
    idx: number;
    videoSrc: any;
}

interface Props {
    dataName?: string;
    section: {
        actionArr: Action[];
        vidArr: Video[];
    };
}

const ProductFeatureVids: React.FC<Props> = ({ section }) => {
    const { t } = useTranslation();
    const [activeIdx, setActiveIdx] = useState<number>(1);

    return (
        <Wrapper className="bg-secondary">
            <Inner>
                <TitleWrapper className="title-video-wrapper">
                    <H4Title className="title-span-secondary">
                        <span>{t('common.section.featureShowcase')}</span>
                    </H4Title>
                </TitleWrapper>
                <ActionBtnsWrapper>
                    {section.actionArr?.map(({ id, idx, txt, icon }) => (
                        <FeatureActionBtn
                            key={id}
                            idx={idx}
                            txt={txt}
                            activeIdx={activeIdx}
                            setActiveIdx={setActiveIdx}
                            icon={icon}
                        />
                    ))}
                </ActionBtnsWrapper>
                <VideoSlides activeIdx={activeIdx} vidArr={section.vidArr} />
            </Inner>
        </Wrapper>
    );
};

export default ProductFeatureVids;
