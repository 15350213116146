import styled from 'styled-components';
import {
    StyledWrapperStandard,
    StyledInner,
} from '../../components/common.style';

export const Wrapper = styled(StyledWrapperStandard)``;

export const Inner = styled(StyledInner)`
    text-align: center;
    max-height: 100vh;
`;

export const ActionBtnsWrapper = styled.div`
    position: relative;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
`;
