import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledWrapper, StyledQuoteInner } from './common.style';
import { H3Title, Text2 } from './common.styles.font';
import { desktopViews, tabletViews } from '../utils/media';

const Wrapper = styled(StyledWrapper)`
    width: 100%;
    color: var(--neutral-50);
    background: var(--bg-secondary-d);
    padding: var(--main-top-padding) var(--main-side-padding)
        var(--main-bottom-padding);
`;

const Inner = styled(StyledQuoteInner)`
    row-gap: 0;
    padding: 0 4rem;

    p:last-of-type {
        color: rgba(255, 255, 255, 0.6);
        margin-top: 3rem;
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        p:last-of-type {
            margin-top: 2rem;
        }
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        padding: 0 4rem;

        p:last-of-type {
            font-size: 2rem;
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        padding: 0;

        p:last-of-type {
            margin-top: 1.6rem;
        }
    }
`;

interface Props {
    curLang: string;
}

const Quote: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Inner className={`quote-${curLang} main`}>
                <H3Title
                    className={`lang-${curLang}`}
                    data-aos-delay="200"
                    data-aos-durtion="200"
                    data-aos="fade-up"
                >
                    {t('main.team.quote')}
                </H3Title>
                <Text2 className={`lang-${curLang}`}>
                    — Manfred Vaillancourt, {t('main.team.position2')}
                </Text2>
            </Inner>
        </Wrapper>
    );
};

export default Quote;
