export const headerHeight = 5;
export const bottomHeight = 5.8;

export const tooltipIndex = 99970;

export const popWrapperIndex = 99980;
export const headerIndex = 90000;
export const bottomIndex = 90000;
export const menuWrapperIndex = headerIndex + 1;
export const sideBarIndex = 90000;

export const solutionIntroIndex = 9999;
export const mainPageBgIndex = 0;
export const mainPageBgActiveIndex = 99999;

export const imageLightboxZIndex = 100001;
