import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Subtitle1, Subtitle2, Text2 } from './common.styles.font';

const Wrapper = styled.div`
    box-shadow:
        0px 4px 6px rgba(0, 0, 0, 0.12),
        0px 0px 20px rgba(0, 0, 0, 0.04);
    background: #fff;
    border-radius: var(--card-bd-r);
    text-align: left;
    width: 100%;
    display: grid;
    grid-template-rows: max-content 1fr;

    img {
        width: 100%;
        border-radius: var(--card-bd-r) var(--card-bd-r) 0 0;
    }
`;

const Inner = styled.div`
    padding: 2.4rem 2rem 2.8rem;
    display: grid;
    grid-template-rows: max-content 1fr;
`;

const CardHeader = styled.div`
    margin-bottom: 1.4rem;
`;

const TagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 1.2rem;
`;

const Tag = styled.div`
    font-family: 'Roboto', 'Assistant', sans-serif;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cfeae6;
    border-radius: var(--tag-bd-r);
    padding: 0.3rem 0.5rem 0.4rem;
    color: var(--tertiary-600);
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.75px;
`;

const CardBody = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 1fr max-content;
    row-gap: 3rem;
`;

const LearnMoreLink = styled.a`
    justify-self: start;
    align-self: flex-start;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--learn-more-bd);
    padding: 0.5rem 1.4rem 0.7rem;
    border: 1px solid var(--link-learn-more-bd);
    background: var(--link-learn-more-bg);
    color: var(--link-learn-more-txt);
    font-family: 'Roboto', 'Assistant', sans-serif;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0.1px;

    &:hover {
        border: 1px solid var(--link-learn-more-bd-h);
        background: var(--link-learn-more-bg-h);
        color: var(--link-learn-more-txt-h);
    }

    &:focus,
    &:active {
        border: 1px solid var(--link-learn-more-bd-f);
        background: var(--link-learn-more-bg-f);
        color: var(--link-learn-more-txt-f);
    }
`;

interface Tag {
    id: string;
    txt: string;
}

interface Props {
    curLang: string;
    img: any;
    tagArr: Tag[];
    title: string;
    subtitle: string;
    description: string;
    clickable: boolean;
    link: string;
}

const CardProduct: React.FC<Props> = ({
    curLang,
    img,
    tagArr,
    title,
    subtitle,
    description,
    clickable,
    link,
}) => {
    const { t } = useTranslation();
    return (
        <Wrapper data-aos-delay="160" data-aos="fade-up">
            <img src={img} alt="product" />
            <Inner>
                <CardHeader>
                    <TagsWrapper>
                        {tagArr.map(({ id, txt }) => (
                            <Tag key={id}>{t(txt)}</Tag>
                        ))}
                    </TagsWrapper>

                    <Subtitle1 className={`lang-${curLang}`}>{title}</Subtitle1>
                    <Subtitle2 className={`lang-${curLang}`}>
                        {subtitle}
                    </Subtitle2>
                </CardHeader>

                <CardBody>
                    <Text2 className={`lang-${curLang}`}>{description}</Text2>
                    {clickable && (
                        <LearnMoreLink href={link} target="_blank">
                            {t('common.btn.learnMore')}
                        </LearnMoreLink>
                    )}
                </CardBody>
            </Inner>
        </Wrapper>
    );
};

export default CardProduct;
