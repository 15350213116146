import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    StyledInner,
    StyledWrapperStandard,
} from '../../components/common.style';
import { Text1, Subtitle1 } from '../../components/common.styles.font';
import videoSrc from '../../assets/products/pdfSplitter/vids/Blubina_PDF_Splitter_230613_multiple recipients.mp4';
import Video from '../../components/Video';
import { tabletViews } from '../../utils/media';

export const Wrapper = styled(StyledWrapperStandard)``;

export const ContentWrapper = styled(StyledInner)`
    display: grid;
    grid-template-columns:
        minmax(38rem, 5fr)
        minmax(6rem, 1fr) minmax(26rem, 40rem);
    grid-template-areas: 'vid . text';
    align-items: center;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        grid-template-columns: 1fr;
        grid-template-areas:
            'vid'
            'text';
        row-gap: 4rem;
    }
`;

const TextWrapper = styled.div`
    grid-area: text;

    p {
        margin-bottom: 3rem;
    }

    .question {
        margin-bottom: 0;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        p {
            margin-bottom: 2.4rem;
        }
    }
`;

const VideoWrapper = styled.div`
    grid-area: vid;
    width: 100%;
`;

interface Props {
    curLang: string;
}

const PDFProductIntroSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();
    return (
        <Wrapper id="product-pdfSplitter__intro" className="bg-tertiary">
            <ContentWrapper>
                <VideoWrapper>
                    <Video
                        videoSrc={videoSrc}
                        isAutoPlay={true}
                        status={'active'}
                    />
                </VideoWrapper>
                <TextWrapper>
                    <Text1 className={`lang-${curLang}`}>
                        {t('product.pdfSplitter.intro.desc1')}
                    </Text1>
                    <Subtitle1 className={`lang-${curLang} question`}>
                        {t('product.pdfSplitter.intro.desc2')}
                    </Subtitle1>
                </TextWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default PDFProductIntroSection;
