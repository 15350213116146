import styled from 'styled-components';
import { desktopViews, mobileViews, tabletViews } from '../../utils/media';
import { headerIndex } from '../../utils/common';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    z-index: ${headerIndex};
    padding: 0.6rem 2rem;
    background: var(--neutral-100);

    &.homepage {
        background: var(--neutral-100);
    }

    &.product {
        background: var(--secondary-50);
    }

    &::before {
        content: '';
        background: var(--neutral-400);
        opacity: 0.4;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding: 1.4rem 2.2rem;
    }
`;

export const Inner = styled.div`
    display: grid;
    grid-template-areas: 'copyright link';
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem 3rem;

    /* max-width: 1040px */
    @media ${desktopViews.xxs} {
        grid-template-areas:
            'link'
            'copyright';
    }

    /* max-width: 480px */
    @media ${mobileViews.xlg} {
        column-gap: 2rem;
    }
`;

export const CopyrightTxt = styled.p`
    grid-area: copyright;
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 1.4rem;
    color: var(--txt-inactive);

    &.lang-pl {
        font-family: 'Noto Sans Condensed', 'Roboto', sans-serif;
        letter-spacing: 1px;
    }
`;

export const LinksWrapper = styled.div`
    grid-area: link;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 2rem;
`;

export const Link = styled.a`
    font-family: 'Assistant', 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-size: 1.5rem;
    color: var(--txt-inactive);
    font-weight: 500;

    &.lang-pl {
        font-family: 'Noto Sans Condensed', 'Roboto', sans-serif;
        letter-spacing: 1px;
    }

    .icon {
        width: 1.3rem;
    }

    &:hover {
        color: var(--txt-active);
    }
`;

export const Line = styled.div`
    color: var(--txt-inactive);
    /* color: rgba(255, 255, 255, 0.6); */

    /* max-width: 414px */
    @media (max-width: 414px) {
        display: none;
    }
`;
