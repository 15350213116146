import React, { useRef, useState } from 'react';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { UilAngleDown } from '@iconscout/react-unicons';
import {
    Wrapper,
    Logo,
    Nav,
    ListItem,
    ListMenuBtn,
    ListMenu,
    LinkBtn,
    Tag,
} from './style.js';
import BlubinaLogo from '../../assets/logo/logo-blubina.png';
import { mainLanguages } from '../../utils/languages';
import { productList } from '../../utils/links';
import useScroll from '../../hooks/useScroll';
import useClickOutside from '../../hooks/useClickOutside';

interface Props {
    curLang: string;
    setCurLang: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<Props> = ({ curLang, setCurLang }) => {
    const { t } = useTranslation();
    const langBtnRef = useRef<HTMLLIElement | null>(null);
    const productBtnRef = useRef<HTMLLIElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const locationArr = location.pathname.split('/');
    const isProductPage = locationArr.includes('product');
    const activeProduct = isProductPage && locationArr[3];
    const [isLangMenuOpen, setLangMenuOpen] = useState<boolean>(false);
    const [isProductsMenuOpen, setProductsMenuOpen] = useState<boolean>(false);

    useClickOutside(langBtnRef, () => setLangMenuOpen(false));
    useClickOutside(productBtnRef, () => setProductsMenuOpen(false));
    const scroll = useScroll();

    const handleLangClick = (code: string) => {
        setLangMenuOpen(false);
        setCurLang(code);
        i18n.changeLanguage(code);
        localStorage.setItem('lang', JSON.stringify(code));
        const curPath = location.pathname;
        const newPath = curPath.replace(/^\/(en|de|pl|zh)/, `/${code}`);
        navigate(newPath);
    };

    const handleProductClick = () => {
        setProductsMenuOpen(false);
    };

    return (
        <Wrapper
            className={`${
                scroll === 'down' ? 'hide' : scroll === 'up' ? 'show' : ''
            }`}
        >
            <Logo href={`/${curLang}`} target="_blank">
                <img
                    className="hero-header_logo-img"
                    src={BlubinaLogo}
                    alt="logo"
                />
            </Logo>

            <Nav>
                <ListItem ref={langBtnRef}>
                    <ListMenuBtn
                        onClick={() => setLangMenuOpen(!isLangMenuOpen)}
                    >
                        <UilAngleDown className="icon icon-arrow-down" />
                        <span>{curLang}</span>
                    </ListMenuBtn>

                    {isLangMenuOpen && (
                        <ListMenu className="header_lang-menu">
                            {mainLanguages.map(({ id, code, name }) => {
                                return (
                                    <li key={id}>
                                        <LinkBtn
                                            className={
                                                i18n.resolvedLanguage === code
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() =>
                                                handleLangClick(code)
                                            }
                                        >
                                            {name}
                                        </LinkBtn>
                                    </li>
                                );
                            })}
                        </ListMenu>
                    )}
                </ListItem>

                <ListItem ref={productBtnRef}>
                    <ListMenuBtn
                        onClick={() => setProductsMenuOpen(!isProductsMenuOpen)}
                    >
                        <UilAngleDown className="icon icon-arrow-down" />
                        <span>{t('main.products.title')}</span>
                    </ListMenuBtn>

                    {isProductsMenuOpen && (
                        <ListMenu className="header_lang-menu">
                            {productList.map(
                                ({ id, name, txt, active, btnAction }) => {
                                    return (
                                        <li key={id}>
                                            <LinkBtn
                                                className={`${
                                                    activeProduct === name
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    handleProductClick();
                                                    btnAction(curLang);
                                                }}
                                            >
                                                {txt}
                                                {!active && (
                                                    <Tag>
                                                        {t(
                                                            'common.txt.comingSoon',
                                                        )}
                                                    </Tag>
                                                )}
                                            </LinkBtn>
                                        </li>
                                    );
                                },
                            )}
                        </ListMenu>
                    )}
                </ListItem>
            </Nav>
        </Wrapper>
    );
};

export default Header;
