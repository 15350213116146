import { v4 as uuidv4 } from 'uuid';

export const mainNavArr = [
    {
        id: uuidv4(),
        idx: 0,
        link: '#main__hero',
        name: 'hero',
        txt: 'common.btn.backTop',
    },
    {
        id: uuidv4(),
        idx: 1,
        link: '#main__challenges',
        name: 'challenges',
        txt: 'main.nav.challenges',
    },
    {
        id: uuidv4(),
        idx: 2,
        link: '#main__solutions',
        name: 'solutions',
        txt: 'main.nav.solutions',
    },
    {
        id: uuidv4(),
        idx: 3,
        link: '#main__products',
        name: 'products',
        txt: 'main.nav.products',
    },
    {
        id: uuidv4(),
        idx: 4,
        link: '#main__team',
        name: 'team',
        txt: 'main.nav.team',
    },
    {
        id: uuidv4(),
        idx: 5,
        link: '#main__partners',
        name: 'partners',
        txt: 'main.nav.partners',
    },
    {
        id: uuidv4(),
        idx: 6,
        link: '#main__contact',
        name: 'contact',
        txt: 'main.nav.contact',
    },
];

export const mainDetails = {
    challenge: [
        {
            id: uuidv4(),
            idx: 0,
            title: 'main.challenges.point1',
            txt: 'main.challenges.desc1',
        },
        {
            id: uuidv4(),
            idx: 1,
            title: 'main.challenges.point2',
            txt: 'main.challenges.desc2',
        },
        {
            id: uuidv4(),
            idx: 2,
            title: 'main.challenges.point3',
            txt: 'main.challenges.desc3',
        },
        {
            id: uuidv4(),
            idx: 3,
            title: 'main.challenges.point4',
            txt: 'main.challenges.desc4',
        },
        {
            id: uuidv4(),
            idx: 4,
            title: 'main.challenges.point5',
            txt: 'main.challenges.desc5',
        },
    ],
};

export const productTags = {
    blubinaCore: [
        { id: uuidv4(), txt: 'product.blubinaCore.tag.tag1' },
        { id: uuidv4(), txt: 'product.blubinaCore.tag.tag2' },
    ],
    pdfSplitter: [
        { id: uuidv4(), txt: 'product.pdfSplitter.tag.tag1' },
        { id: uuidv4(), txt: 'product.pdfSplitter.tag.tag2' },
    ],
    uPointDMS: [
        { id: uuidv4(), txt: 'product.uPointDMS.tag.tag1' },
        { id: uuidv4(), txt: 'product.uPointDMS.tag.tag2' },
    ],
};
