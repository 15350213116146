import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    UilRuler,
    UilClockThree,
    UilChannel,
    UilShieldCheck,
} from '@iconscout/react-unicons';
import {
    StyledWrapperStandard,
    CardLayoutInner,
    TitleWrapper,
} from '../../components/common.style';
import { H4Title } from '../../components/common.styles.font';
import SolutionIntro from '../../components/SolutionIntro';
import CardIcon from '../../components/CardIcon';

const Wrapper = styled.div`
    width: 100%;
`;

interface Props {
    curLang: string;
}

const SolutionSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <Wrapper id="main__solutions">
            <SolutionIntro curLang={curLang} />
            <StyledWrapperStandard className="bg-primary">
                <TitleWrapper className="title-card-wrapper">
                    <H4Title
                        className="title-span-primary"
                        data-aos-delay="80"
                        data-aos="fade-up"
                    >
                        <span>{t('main.solutions.title')}</span>
                    </H4Title>
                </TitleWrapper>

                <CardLayoutInner className="col-2">
                    <CardIcon
                        curLang={curLang}
                        title={t('main.solutions.point1')}
                        txt={t('main.solutions.desc1')}
                        icon={<UilRuler className="icon icon-ruler" />}
                    />

                    <CardIcon
                        curLang={curLang}
                        title={t('main.solutions.point2')}
                        txt={t('main.solutions.desc2')}
                        icon={<UilChannel className="icon icon-channel" />}
                    />

                    <CardIcon
                        curLang={curLang}
                        title={t('main.solutions.point3')}
                        txt={t('main.solutions.desc3')}
                        icon={<UilClockThree className="icon icon-time" />}
                    />

                    <CardIcon
                        curLang={curLang}
                        title={t('main.solutions.point4')}
                        txt={t('main.solutions.desc4')}
                        icon={<UilShieldCheck className="icon icon-check" />}
                    />
                </CardLayoutInner>
            </StyledWrapperStandard>
        </Wrapper>
    );
};

export default SolutionSection;
