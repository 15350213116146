import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../../components/Header/Header';
import Footer from '../../sections/footer/Footer';

const Wrapper = styled.div`
    background: #fff;
`;

interface Props {
    curView: string | null;
    curLang: string;
    setCurLang: React.Dispatch<React.SetStateAction<string>>;
}

const RootPage: React.FC<Props> = ({ curView, curLang, setCurLang }) => {
    return (
        <Wrapper>
            <Header curLang={curLang} setCurLang={setCurLang} />
            <Outlet />
            <Footer curView={curView} curLang={curLang} />
        </Wrapper>
    );
};

export default RootPage;
