import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    StyledWrapperStandard,
    TitleWrapper,
} from '../../components/common.style';
import PricingCard from '../../components/PricingCard';
import { CardsWrapper, IntroWrapper, CardIntroWrapper } from './style';
import { H4Title, H5Intro, Text1 } from '../../components/common.styles.font';

interface Feature {
    id: string;
    txt: string;
}

interface PricingCard {
    id: string;
    idx: number;
    category: string;
    title: string;
    price: {
        title: string;
        unit: string;
        method: string;
    };
    customPrice: null | string;
    featuresArr: Feature[];
    isFreeTrialBtn: boolean;
}

interface Props {
    curLang: string;
    section: {
        intro: string;
        sideIntro: string;
        pricingArr: PricingCard[];
    };
}

const ProductPricingSection: React.FC<Props> = ({ curLang, section }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard className="bg-tertiary">
            <TitleWrapper className="title-intro-wrapper">
                <H4Title className="title-span-tertiary">
                    <span>{t('common.pricing.title')}</span>
                </H4Title>
            </TitleWrapper>
            <IntroWrapper>
                <Text1 className={`lang-${curLang}`}>
                    <Trans i18nKey={t(section.intro)} />
                </Text1>
            </IntroWrapper>
            <CardsWrapper>
                <CardIntroWrapper>
                    <H5Intro className={`lang-${curLang} intro-thin`}>
                        {t(section.sideIntro)}
                    </H5Intro>
                </CardIntroWrapper>
                {section?.pricingArr.map(
                    ({
                        id,
                        category,
                        title,
                        price,
                        customPrice,
                        featuresArr,
                        isFreeTrialBtn,
                    }) => (
                        <PricingCard
                            key={id}
                            curLang={curLang}
                            category={category}
                            title={title}
                            price={price}
                            customPrice={customPrice}
                            featuresArr={featuresArr}
                            isFreeTrialBtn={isFreeTrialBtn}
                        />
                    ),
                )}
            </CardsWrapper>
        </StyledWrapperStandard>
    );
};

export default ProductPricingSection;
