import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Head from '../../components/Head';
import Lightbox from '../../components/Lightbox';
import ProductHeroSection from '../../sections/productHero/ProductHeroSection';
import ProductIntroSection from '../../sections/productIntro/ProductIntroSection';
import PDFProductIntroSection from '../../sections/pdfProductIntro/PDFProductIntroSection';
import ProductFeatureSection from '../../sections/productFeature/ProductFeatureSection';
import ProductOverviewSection from '../../sections/productOverview/ProductOverviewSection';
import ProductMoreVideoSection from '../../sections/productMoreVideo/ProductMoreVideoSection';
import ProductFeaturesVids from '../../sections/productFeatureVids/ProductFeaturesVids';
import ProductExplanationSection from '../../sections/productExplanation/ProductExplanationSection';
import PricingSection from '../../sections/productPricing/ProductPricingSection';
import ProductRequestSection from '../../sections/productRequest/ProductRequestSection';
import GoTopBtn from '../../components/buttons/GoTopBtn';
import ProductRequirementSection from '../../sections/productRequirement/ProductRequirement';
import { mainPageBgActiveIndex, mainPageBgIndex } from '../../utils/common';

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: var(--bg-primary);
`;

const Bg = styled.div`
    width: 100%;
    height: 100%;
    background: var(--bg-tertiary-d);
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${mainPageBgIndex};
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
        opacity: 1;
        z-index: ${mainPageBgActiveIndex};
    }
`;

interface Props {
    curLang: string;
    productDetailsObj: any;
    productFeatureSection: any;
}

const ProductPage: React.FC<Props> = ({
    curLang,
    productDetailsObj,
    productFeatureSection,
}) => {
    const { t } = useTranslation();
    const [popupImg, setPopupImg] = useState<any>(null);
    const [isLightboxOpen, setLightboxOpen] = useState(false);

    const openLightbox = (targetImg: any) => {
        setLightboxOpen(true);
        setPopupImg(targetImg);
        document.body.classList.add('popup-open');
    };

    const closeLightbox = () => {
        setPopupImg(null);
        setLightboxOpen(false);
        document.body.classList.remove('popup-open');
    };

    return (
        <>
            <Head
                title={t(productDetailsObj.appTitle)}
                desc={t(productDetailsObj.appDesc)}
            />

            <Wrapper>
                <Bg id={`product-${productDetailsObj.dataName}__bg-whole`} />

                <Lightbox
                    popupImg={popupImg}
                    isLightboxOpen={isLightboxOpen}
                    closeLightbox={closeLightbox}
                />

                <ProductHeroSection
                    dataName={productDetailsObj.dataName}
                    curLang={curLang}
                    heroDetails={productDetailsObj.heroDetails}
                    openLightbox={openLightbox}
                />

                {productDetailsObj.dataName === 'uPoint' && (
                    <ProductIntroSection
                        curLang={curLang}
                        dataName={productDetailsObj.dataName}
                        section={productDetailsObj.intro}
                    />
                )}

                {productDetailsObj.dataName === 'pdfSplitter' && (
                    <PDFProductIntroSection curLang={curLang} />
                )}

                {productDetailsObj.overview && (
                    <ProductOverviewSection
                        curLang={curLang}
                        section={productDetailsObj.overview}
                    />
                )}

                <ProductFeatureSection
                    curLang={curLang}
                    dataName={productDetailsObj.dataName}
                    section={productFeatureSection}
                    openLightbox={openLightbox}
                />

                {productDetailsObj.moreVideos && (
                    <ProductMoreVideoSection
                        curLang={curLang}
                        dataName={productDetailsObj.dataName}
                        section={productDetailsObj.moreVideos}
                    />
                )}

                {productDetailsObj.vidDetails && (
                    <ProductFeaturesVids
                        section={productDetailsObj.vidDetails}
                    />
                )}

                {productDetailsObj.explanationDetails && (
                    <ProductExplanationSection
                        curLang={curLang}
                        dataName={productDetailsObj.dataName}
                        section={productDetailsObj.explanationDetails}
                        openLightbox={openLightbox}
                    />
                )}

                {productDetailsObj.requirement && (
                    <ProductRequirementSection
                        curLang={curLang}
                        dataName={productDetailsObj.dataName}
                        section={productDetailsObj.requirement}
                        openLightbox={openLightbox}
                    />
                )}

                <PricingSection
                    curLang={curLang}
                    section={productDetailsObj.pricingSection}
                />
                <ProductRequestSection
                    curLang={curLang}
                    section={productDetailsObj.requestSection}
                />
                <GoTopBtn />
            </Wrapper>
        </>
    );
};

export default ProductPage;
