import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { sideBarIndex } from '../../utils/common';
import { mainScrollHandler } from '../../helpers/scrollBar';
import { mainNavArr } from '../../data/mainDetails';

const Wrapper = styled.ul`
    display: none;
    position: fixed;
    top: 15rem;
    right: 1rem;
    margin-top: 6rem;
    z-index: ${sideBarIndex};
    width: 2rem;
    height: 28rem;
    flex-direction: column;
    gap: 1.8rem;
    transition: height 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    display: flex;
`;

const Bar = styled.div`
    position: absolute;
    top: 1rem;
    right: 0.6rem;
    background: var(--bar);
    width: 2px;
    height: 100%;

    &::before {
        content: '';
        width: 2px;
        height: var(--bar-fill);
        background-color: var(--bar-fill-d);
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const ListItem = styled.a`
    position: absolute;
    top: 0;
    right: 0;

    &.link-top {
        top: 0;
    }

    &.link-challenges {
        top: 15%;
    }

    &.link-solutions {
        top: 30%;
    }

    &.link-products {
        top: 53%;
    }

    &.link-team {
        top: 68%;
    }

    &.link-partners {
        top: 88%;
    }

    &.link-contact {
        top: 100%;
    }

    &:hover .link-text {
        display: block;
    }
`;

export const CircleLink = styled.div`
    cursor: pointer;
    position: relative;
`;

export const Circle = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: var(--circle);
    border: 1px solid #000000;

    &.active {
        width: 1.2rem;
        height: 1.2rem;
        right: 2px;
        background: var(--circle-active);
        border: 1px solid #000000;
    }
`;

export const LinkText = styled.p`
    position: absolute;
    top: 0;
    right: 2.2rem;
    transform: translateY(-20%);
    border-radius: var(--tag-bd-r);
    color: var(--sidenav-link-txt) !important;
    background: var(--sidenav-link-bg);
    padding: 2px 6px;
    display: none;
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 1.6rem;

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 1.4rem;
    }
`;

interface Props {
    curLang: string;
    curView: string | null;
}

const MainSideNav: React.FC<Props> = ({ curLang, curView }) => {
    const { t } = useTranslation();
    const [activeSecIdx, setActiveSecIdx] = useState<number>(0);
    const [scrollActiveIdx, setScrollActiveIdx] = useState<number>(0);

    useEffect(() => {
        const handleScroll = () => {
            if (curView === 'homepage') {
                setScrollActiveIdx(0);
            }

            setScrollActiveIdx(mainScrollHandler());
        };
        window.addEventListener('scroll', () => handleScroll());
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        setActiveSecIdx(scrollActiveIdx);
    }, [scrollActiveIdx]);

    return (
        <Wrapper>
            <Bar />

            {mainNavArr.map(({ id, idx, link, name, txt }) => (
                <ListItem
                    key={id}
                    className={`link-${name}`}
                    onClick={() => setActiveSecIdx(idx)}
                    href={link}
                >
                    <CircleLink>
                        <Circle
                            className={`link-circle circle-${name} ${
                                activeSecIdx === idx ? 'active' : ''
                            }`}
                        />
                        <LinkText className={`lang-${curLang} link-text`}>
                            {t(txt)}
                        </LinkText>
                    </CircleLink>
                </ListItem>
            ))}
        </Wrapper>
    );
};

export default MainSideNav;
