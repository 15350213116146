import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { tabletViews } from '../../utils/media';
import { StyledInner } from '../../components/common.style';
import Button from '../../components/buttons/Button';
import { H2Title } from '../../components/common.styles.font';

const Wrapper = styled.div`
    width: 100%;
    color: var(--neutral-50);
    background: var(--bg-secondary-d);
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: var(--main-side-padding);
    padding-right: var(--main-side-padding);
    overflow-y: hidden;

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
`;

const Inner = styled(StyledInner)`
    position: relative;
    text-align: center;
    display: grid;
    justify-items: center;
    row-gap: 2.4rem;

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        row-gap: 2rem;
    }
`;

interface Props {
    curLang: string;
    section: {
        productName: 'string';
        btnTxt: 'string';
        btnAction: (arg0?: string) => void;
    };
}

const ProductRequestSection: React.FC<Props> = ({ curLang, section }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Inner>
                <H2Title className={`lang-${curLang}`}>
                    {t('common.txt.demoDesc1')}
                    <span> {section.productName} </span>
                    {t('common.txt.demoDesc2')}
                </H2Title>
                <Button
                    fs="18"
                    appearance="light-outline"
                    width="fit-content"
                    txt={t(section.btnTxt)}
                    handleClick={() => section.btnAction(curLang)}
                />
            </Inner>
        </Wrapper>
    );
};

export default ProductRequestSection;
