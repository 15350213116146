import styled from 'styled-components';
import { StyledInner } from '../../components/common.style';
import { desktopViews, tabletViews } from '../../utils/media';

export const IntroWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100rem;
    text-align: center;
    margin: 0 auto 8rem;

    strong {
        font-weight: 500;
        color: var(--txt-active);
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        max-width: 80rem;
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        &.big {
            display: block;
        }
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        max-width: 72rem;
        &.big {
            max-width: 72rem;
        }
    }
`;

export const CardIntroWrapper = styled.div`
    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        display: none;
    }
`;

export const CardsWrapper = styled(StyledInner)`
    display: grid;
    align-items: stretch;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    column-gap: 3rem;
    row-gap: 4rem;

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        grid-template-columns: 1fr;
    }
`;
